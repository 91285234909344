:root {
    --velocity: 0.8s;
    --steps: 6;
    --height: 127.5px;
    --delay: 250ms;
    --multiplier: 1.02;
}

/*
 * 640px is the breakpoint for mobile devices
 * The carousel will be slower and have less steps
 * to make it more readable
 */
@media (max-width: 768px) {
    :root {
        --velocity: 0.8s;
        --steps: 6;
        --height: 75px;
        --delay: 250ms;
        --multiplier: 1.02;
    }
}

.container {
    /*width: 300px;*/
    height: var(--height);
    line-height: calc(var(--height) * 1px);
    margin: auto;
    clip-path: inset(2px 0px);
}

.text {
    font-size: 85px;
}

.list-item {
    list-style:none;
}

.list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    animation: move calc(var(--velocity) * var(--steps)) steps(var(--steps)) 1;

    animation-delay: var(--delay);
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.list-item {
    white-space: nowrap;
    animation: liMove var(--velocity) var(--steps);

    animation-delay: var(--delay);
    animation-direction: normal;
    animation-fill-mode: forwards;
}

@keyframes move {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, calc(var(--steps) * var(--height) * -1 * var(--multiplier)));
    }
}

@keyframes liMove {
    0% {
        transform: translate(0, 0);
    }
    80%,
    100%  {
        transform: translate(0, calc(var(--height) * -1 * var(--multiplier)));
    }
}